@use '../../vars.scss' as *;

#join-section {
  @include flex(column, space-between, center);

  #main-word {
    align-self: flex-start;
    position: relative;

    &:after {
      content: "🚀";
      position: absolute;
      top: 45%;
      right: -250px;
      font-size: $text-xxl;
      animation: 6s linear infinite animateRocket;
    }
  }

  #swun-goal {
    .first-word, .embleme {
      filter: drop-shadow(0 0 35px rgba($primary, 0.8));
      color: $primary;
    }

    .animate-embleme {
      animation-name: animateEmbleme;
      animation-duration: 0.5s;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards;
      opacity: 0;

      &#embleme-2 {
        animation-delay: 0.5s;
      }

      &#embleme-3 {
        animation-delay: 1s;
      }

      &#embleme-4 {
        animation-delay: 1.5s;
      }
    }
  }

  #advantages {
    margin: 100px 0 50px 0;
  }

  #advantages-list {
    list-style: none;
    padding: 0;
    font-size: $text-medium;

    li {
      margin-bottom: 20px;
      color: $gray;
      animation-name: animateAdvantage;
      animation-duration: 0.5s;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards;
      opacity: 0;
      transform: translate(-50px, 0);

      .sentence-start {
        font-weight: 600;
      }

      .emoji {
        margin-right: 12px;
      }

      &:nth-child(1) {
        animation-delay: 2.5s;
      }
      &:nth-child(2) {
        animation-delay: 3s;
      }
      &:nth-child(3) {
        animation-delay: 3.5s;
      }
      &:nth-child(4) {
        animation-delay: 4s;
      }
    }
  }

  button {
    margin: 50px 0;
  }

  #already-signed-counter {
    margin-top: 50px;
    
    h3 {
      font-size: calc($text-huge * 1.15);
    }
  }

  .underlined {
    color: $primary;
    position: relative;
    
    &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 4px;
      background: $primary;
    }
  }

  button {
    font-size: $text-medium;
  }
}

@keyframes animateRocket {
  0% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-in-out;
  }
  25% {
    transform: translate3d(15px, -15px, 0);
    animation-timing-function: ease-in-out;
  }
  50% {
    transform: translate3d(-10px, 10px, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes animateEmbleme {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animateAdvantage {
  from {
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}