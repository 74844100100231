@use '../../vars.scss' as *;

#form-wrapper {
  width: 100%;
  margin-top: 150px;
  @include flex(column, space-between, center);

  #reset-pwd-success {
    font-size: $text-small;
    margin-bottom: 50px;
    text-align: center;
  }
}