$primary: rgb(251, 109, 12);
$primary-light: lighten($primary, 15%);
$white: white;
$black: #1E1E1E;
$gray: #a09892;
$green: #8FF964;
$red: #F94B4B;
$orange: rgb(252, 166, 53);

$sides-mask: linear-gradient(to right,transparent 0%, black 3%, black 97%, transparent 100%);
$black-shadow: drop-shadow(0 0 20px $black);
$base-radius: 20px;

$text-xxl: clamp(42px, 9.67vw, 116px);
$text-xl: clamp(36px, 6vw, 85px);
$text-huge: clamp(26px, 3.34vw, 40px);
$text-big: clamp(22px, 2.67vw, 32px);
$text-medium: clamp(16px, 1.8vw, 20px);
$text-small: clamp(13px, 1.33vw, 16px);

$part-width: min(100%, 1200px);

@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}