@use '../../vars.scss' as *;

#leave-print-section {
  @include flex(column, space-between, center);
  padding-bottom: 50px;

  #main-word {
    align-self: flex-start;
  }

  #paragraph {
    text-align: center;
    margin-bottom: 100px;
  }

  #input {
    width: min(100%, 800px);
    position: relative;
    margin-bottom: 100px;

    &::after {
      content: attr(data-count);
      position: absolute;
      bottom: -2em;
      right: 0;
      font-size: $text-small;
      color: $gray;
    }

    .input {
      width: 100%;
    }

    &:has(.input-invalid) {
      &::after {
        color: $red;
      }
    }
  }
  
  textarea {
    resize: none;
    height: 110px;
  }

  #error {
    text-align: center;
    margin-bottom: 50px;
    
    p {
      color: $red;
      font-size: $text-small;
    }

    #error-msg {
      color: $white;
      margin: 0;
      font-size: $text-medium;
      padding: 10px 20px;
      background: rgba($red, 0.33);
      border-radius: $base-radius;
    }
  }

  #success {
    font-size: $text-huge;
    margin: 0;
  }

  #success-subtitle {
    margin-bottom: 150px;
  }
}