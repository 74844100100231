@use '../../vars.scss' as *;

.legal-title {
    margin-top: 140px;
    width: 80%;
}

.legal-content {
  width: 100%;
  @include flex(column, start, center);

  #application-date {
    text-align: center;
    font-size: $text-medium;
    font-weight: 500;
    margin: 50px 0;
  }

  #content-main {
    width: 100%;
    @include flex(column, start, center);
  }
}

.legal-article {
  width: 80%;
  margin-top: 100px;
  @include flex(column, start, start);

  #article-title {
    text-align: left;
    width: 100%;
    font-size: $text-big;
  }

  p {
    text-align: justify;
    font-size: $text-small;
    line-height: 20px;
  }

  a {
    color: $primary;
  }

  strong {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: $white;
  }

  table {
    border: 2px solid $white;
  }

  td, th {
      padding: 10px;
      border-bottom: 1px solid $white;
  }

  tr:nth-last-child(1) td {
      border-bottom: none;
  }

  th:nth-last-child(1), td:nth-last-child(1) {
      border-left: 1px solid $white;
  }
}

.legal-subarticle {
  width: 100%;
  @include flex(column, start, start);
  padding-left: 75px;

  #subarticle-title {
    font-size: $text-big;
  }
}