@use '../../vars.scss' as *;

#register-section, #register-organisation-section {
  #forms-wrapper {
    @include flex(row, space-between, start);
    width: 200%;
    margin-top: 150px;
  
    #account-form {
      width: 50%;
      transition-duration: .5s;
      transition-timing-function: ease-in-out;
      position: relative;
      @include flex(column, space-between, center);

      &.active {
        transform: translate(-100%, 0);
        opacity: 1;
      }

      &.inactive {
        transform: translate(0, 0);
        opacity: 0;
      }

      #back-arrow {
        position: absolute;
        top: 50%;
        left: 0;
        font-size: $text-huge;
        margin: 0;
        transform: translate(50%, -50%);
        cursor: pointer;
        transition-duration: .3s;
        
        &:hover {
          transform: translate(50%, -50%) scale(1.2);
        }
        
        @media screen and (max-width: 800px) {
          top: 0;
          left: 50%;
          transform: translate(-50%, -150%);

          &:hover {
            transform: translate(-50%, -150%) scale(1.2);
          }
        }
      }
    }
  }

  #register-form {
    width: 50%;
    @include flex(column, flex-start, center);
    overflow: visible;
    transition-duration: .5s;
    transition-timing-function: ease-in-out;

    &.active {
      transform: translate(0, 0);
      opacity: 1;
    }

    &.inactive {
      transform: translate(-100%, 0);
      opacity: 0;
    }
  
  }

  #invalid-mail {
    color: $red;
  }

  #pwd-no-match {
    color: $red;
  }

  #mdp-strength {
    width: min(400px, 100%);

    p {
      color: $gray;
      font-size: $text-small;
    }
    
    #level {
      transition-duration: .5s;
      font-weight: 600;
      margin-left: 5px;
    }

    &.insufficient {
      #level {
        color: $gray;
      }

      #bar-indicator::after {
        background: $gray;
        width: 10%;
      }
    }

    &.poor {
      #level {
        color: $red;
      }

      #bar-indicator::after {
        background: $red;
        width: 30%;
      }
    }

    &.okay {
      #level {
        color: $orange;
      }

      #bar-indicator::after {
        background: $orange;
        width: 60%;
      }
    }

    &.strong {
      #level {
        color: $green;
      }

      #bar-indicator::after {
        background: $green;
        width: 100%;
      }
    }

    #bar-indicator {
      position: relative;
      width: 100%;
      height: 6px;
      background: $gray;
      border-radius: 3px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        border-radius: 3px;
        transition-duration: .5s;
      }
    }
  }

  #cgu-accept {
    @include flex(row, space-around, center);
    width: min(100%, 800px);

    p {
      font-size: $text-small;
    }

    a {
      color: $primary;
      font-weight: 600;
    }
  }
}

#register-organisation-section {
  #forms-wrapper {
    flex-direction: column;
    align-items: center;
    width: 100%;
  
    .input {
      margin-bottom: 25px;
    }
  }

  padding-bottom: 50px;
}

@keyframes animateOpacityOnEnter {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}