@use '../../../vars.scss' as *;

#survey-header {
  p {
    text-align: center;
  }

  #credit-msg {
    margin-top: 50px;
    margin-bottom: 5px;
  }

  #credit-condition {
    font-size: small;
    margin-top: 0;
  }

  mark {
    background: none;
    color: $primary;
    font-weight: 600;
  }
}

#survey {
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;

  .survey-question {
    width: min(100%, 800px);

    h2, h3 {
      position: relative;
    }

    &.mandatory h2::before, &.mandatory h3::before {
      content: '*';
      position: absolute;
      top: 50%;
      left: 0;
      margin: 0;
      font-size: $text-big;
      transform: translate(-150%, -40%);
    }

    .question-choices {
      width: 100%;
      padding: 20px;
    }

    .choice {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      label {
        cursor: pointer;
      }
    }

    .input {
      width: 100%;
    }

    .question-rating {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
      align-items: center;

      .rating-star {
        position: relative;
        width: min(80px, 100vw / 8);
        height: min(80px, 100vw / 8);
        background: rgba($white, 0.3);
        cursor: pointer;
        clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
      }

      .star-half::after, .star-full::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: $primary;
      }

      .star-full::after {
        width: 100%;
      }
    }
  }
}

#forbidden-msg {
  text-align: center;
}

#submitted-msg {
  display: flex;
  flex-direction: column;
  align-items: center;

  #thanks-word {
    margin-bottom: 100px;
    text-align: center;
  }
}