@use '../vars.scss' as *;

#words-list-container {
  display: inline-flex;
  width: 100%;
  overflow-x: hidden;
  mask-image: $sides-mask;
}

.words-list {
  display: inline-flex;
  list-style: none;
  animation-timing-function: linear;
  animation-name: scrollWords;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  transform: translate3d(0, 0, 0);
  
  &::-webkit-scrollbar {
    display: none;
  }
}

.user-word {
  width: min(400px, 60vw);
  margin: max(5vw, 50px);
  position: relative;

  @include flex(column, flex-start, end);

  &:nth-child(1) {
    margin-left: 0;
  }

  p {
    text-align: left;
    font-weight: lighter;
  }

  h3 {
    width: fit-content;
    text-align: right;
  }

  &.vip {
    &::before, &::after {
      content: '✨';
      position: absolute;
      font-size: 48px;
      margin: 0;
      z-index: -1;
    }
    
    &::before {
      top: 0;
      left: 0;
      transform: translate(-100%, -50%);
      animation: shineStar1 3s ease-in-out infinite both;
    }
    
    &::after {
      bottom: 0;
      right: 0;
      transform: translate(100%, 50%);
      animation: shineStar2 4s ease-in-out .5s infinite both;
    }

    h3 {
      color: transparent;
      background: linear-gradient(to right, #FB0C22, $primary);
      background-clip: text;
      filter: drop-shadow(0 0 15px $primary);
    }
  }
}

@keyframes scrollWords {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-100%, 0);
  }
}

@keyframes shineStar1 {
  0% {
    transform: translate(-100%, -50%) scale(1);
    filter: drop-shadow(0 0 15px rgba($white, 0.3));
  }
  50% {
    transform: translate(-100%, -50%) scale(1.2);
    filter: drop-shadow(0 0 15px rgba($white, 0.4));
  }
  100% {
    transform: translate(-100%, -50%) scale(1);
    filter: drop-shadow(0 0 15px rgba($white, 0.3));
  }
}

@keyframes shineStar2 {
  0% {
    transform: translate(100%, 50%) scale(1);
    filter: drop-shadow(0 0 15px rgba($white, 0.3));
  }
  50% {
    transform: translate(100%, 50%) scale(1.2);
    filter: drop-shadow(0 0 15px rgba($white, 0.4));
  }
  100% {
    transform: translate(100%, 50%) scale(1);
    filter: drop-shadow(0 0 15px rgba($white, 0.3));
  }
}