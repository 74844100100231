@use '../../vars.scss' as *;

#register-section, #login-section, #register-organisation-section  {
  #main-word {
    width: fit-content;
    align-self: flex-start;
    position: relative;
  
    &::after {
      content: '🙋‍♂️📝';
      position: absolute;
      bottom: 20%;
      right: -350px;
      font-size: $text-xxl;
    }
  }

  .input {
    margin-bottom: 25px;
  }

  #error-msg {
    @include flex(column, space-between, center);
    animation: animateOpacityOnEnter .3s ease-out forwards;

    #error-code {
      color: $white;
      margin: 0;
      padding: 10px 20px;
      background: rgba($red, 0.33);
      border-radius: $base-radius;
    }

    p {
      color: $red;
      width: fit-content;
      margin: 10px 0;

      &:nth-last-child(1) {
        font-size: $text-small;
        margin-top: 40px;
      }
    }
  }

  > button, > #loading {
    position: relative;
    left: 50%;
    margin-top: 100px;
    transform: translate(-50%, 0);
  }
  
  > .link {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    width: fit-content;
    text-align: center;
  }
}