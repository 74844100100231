button.secondary {
  #rocket {
    display: inline-block;
    margin-left: 5px;
    animation-duration: 4s;
    animation-fill-mode: forwards;
  }
  
  &:hover {
    #rocket {
      animation-name: rocketLaunch;
    }
  }
}

@keyframes rocketLaunch {
  0% {
    transform: scale(1) translate(0, 0);
    animation-timing-function: cubic-bezier(1, 0, 0, 1);
  }
  @for $i from 1 through 12 {
    #{1.25% * (2 * $i + 1)} {
      transform: translate(2px, 2px);
    }
  }
  @for $i from 1 through 11 {
    #{1.25% * 2 * $i} {
      transform: translate(-2px, -2px);
    }
  }
  40% {
    transform: scale(4) translate(0, 0);
    animation-timing-function: cubic-bezier(0.8, 0, 0.5, 1);
  }
  100% {
    transform: scale(4) translate(1500%, -1000%);
  }
}