@use '../vars.scss' as *;

.title {
  #title-main {
    margin: 0;
    font-size: $text-xl;
    line-height: $text-xl;
  }

  #title-complement {
    margin: 5px 0;
    font-size: $text-big;
    font-weight: 500;
  }
}