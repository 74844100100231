@use '../../vars.scss' as *;

$love: rgb(251, 12, 36);
$wow: rgb(251, 211, 12);
$no: rgb(170, 170, 170);

#decoration-wow p {
  font-size: 256px;
  transform: rotate(10deg);
  margin: 0;
  filter: drop-shadow(0 0 100px rgba($wow, 0.6));
}

#decoration-love p {
  font-size: 312px;
  transform: rotate(-8deg);
  margin: 0;
  filter: drop-shadow(0 0 100px rgba($love, 0.8));
}

#sports, #sports-content {
  @include flex(column, flex-start, center);

  #main-word {
    align-self: flex-start;
  }

  #sub-paragraph {
    text-align: center;
  }

  .paragraph {
    text-align: center;
  }

  em {
    color: $primary;
    filter: drop-shadow(0 0 20px rgba($primary, 0.4));
  }
  
  #encouragement {
    font-size: $text-huge;
    margin-bottom: 150px;
    position: relative;
    
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 110%;
      height: 4px;
      border-radius: 2px;
      background: $white;
      transform: translate(-5%, 0);
    }
  }

  #page-switcher {
    align-self: center;
    width: min(100%, 800px);
    position: relative;
    @include flex(row, space-between, center);

    p {
      margin: 20px 0;
      cursor: pointer;
    }

    #thumb {
      position: absolute;
      top: 0;
      height: 100%;
      width: min(60%, 320px);
      background: $primary;
      z-index: -1;
      border-radius: 30px;
      transition-duration: .6s;
      transition-timing-function: cubic-bezier(.47,1.34,.41,1);
      
      &.page-0 {
        left: 0;
        transform: translate3d(-13%, 0, 0);
      }

      &.page-1 {
        left: 100%;
        transform: translate3d(-80%, 0, 0);
      }
    }
  }

  #content-wrapper {
    width: 200%;
    @include flex(row, space-between, flex-start);

    > div {
      width: 50%;
      transition-duration: .6s;
      transition-timing-function: cubic-bezier(.47,1.34,.41,1);
    }
  }

  #sports-page {
    @include flex(column, space-between, center);
    padding-top: 80px;
    
    &.active {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    
    &.inactive {
      transform: translate3d(-100%, 0, 0);
      opacity: 0;
    }
  }
  
  #add-sport-page {
    @include flex(column, space-between, center);

    &.active {
      transform: translate3d(-100%, 0, 0);
      opacity: 1;
    }
  
    &.inactive {
      transform: translate3d(0, 0, 0);
      opacity: 0;
    }

    input {
      margin-top: 50px;
    }

    #duplicate {
      background: rgba($primary-light, 0.5);
      padding: 10px 20px;
      margin-top: 50px;
      border-radius: $base-radius;
    }
    
    button {
      margin-top: 50px;
    }

    #success {
      font-size: $text-huge;
    }
  
    #success-subtitle {
      text-align: center;
    }
  }

  #sports-list {
    list-style: none;
    width: 100%;
    padding: 0;
    @include flex(row, center, center);
    flex-wrap: wrap;
    
    li {
      width: min(95%, 450px);
      margin: 0 100px 50px 0;
    }
  }

  .sport-card {
    display: flex;
    flex-direction: column;
    position: relative;
    transform: scale(0);
    animation: popSportCard .6s cubic-bezier(.47,1.34,.41,1) forwards;

    &.user-love {
      #description {
        outline: solid 3px $love;
      }
    }
    
    &.user-wow {
      #description {
        outline: solid 3px $wow;
      }
    }
    
    &.user-no {
      #description {
        outline: solid 3px $no;
      }
    }

    #user-reaction {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      font-size: calc($text-huge * 2);
      z-index: 1;
      transform: scale(0);
      transform-origin: center;
      animation: popReaction .4s cubic-bezier(.47,1.34,.41,1) forwards;
    }
    
    #description {
      @include flex(row, center, center);
      padding: 20px;
      background: rgba($white, 0.05);
      backdrop-filter: blur(20px);
      border-radius: calc($base-radius * 1.2);
      transition-duration: .3s;
    }

    #emoji {
      font-size: calc($text-huge * 2);
      margin: 0;
      margin-right: 0.3em;
    }

    #name {
      font-size: $text-big;
      margin: 0;
    }

    .submitter {
      font-size: $text-small;
      align-self: flex-end;
    }

    .sport-reactions {
      width: 100%;
      margin-top: 10px;
      @include flex(row, space-around, center);

      .reaction {
        margin: 0;
        padding: 5px 15px;
        border-radius: 24px;
        display: inline-flex;
        align-items: center;
        cursor: pointer; 
        transition-duration: .3s;

        p {
          margin: 0;
        }
        
        #emoji {
          font-size: $text-big;
          transition-duration: .3s;
        }

        &:not(.forbidden):hover {
          filter: drop-shadow(0 0 15px rgba($black, 0.6));

          #emoji {
            transform: scale(1.2);
          }
        }

        &#love.selected {
          filter: drop-shadow(0 0 25px $love);
        }

        &#wow.selected {
          filter: drop-shadow(0 0 25px $wow);
        }

        &#no.selected {
          filter: drop-shadow(0 0 25px $no);
        }

        &.forbidden {
          cursor: not-allowed;

          filter: grayscale(1);
        }
      }
    }
  }
}

#sports-content {
  margin-top: 0;
  align-items: flex-start;
}

@media screen and (max-width: 800px) {
  #sports, #sports-content {
    #sports-list li {
      margin: 0 0 50px 0;
    }
  }
}

@keyframes popSportCard {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes popReaction {
  from {
    transform: scale(0) translate(40%, -40%) rotate(6deg);
  }
  to {
    transform: scale(1) translate(40%, -40%) rotate(6deg);
  }
}