@use '../../vars.scss' as *;

#calshare-page {
  @include flex(column, start, center);
  padding: 0 0 40px 0;

  #main-title {
    @include flex(row, start, center);

    img {
      width: clamp(50px, 20%, 140px);
      margin-right: 50px;
      padding: 10px 0;
      animation: breathe 2s ease-in-out infinite alternate-reverse;
    }

    .paragraph {
      font-size: $text-big;
      margin: 0;

      .first-word {
        font-size: calc($text-big * 2.5);
        color: $primary;
        border-bottom: solid 8px $white;
        margin-right: 18px;
      }
    }
  }

  #goals-illu {
    position: relative;
    width: 80%;
    margin: 180px 0;
    @include flex(row, space-between, stretch);

    div {
      position: relative;
      width: 20%;
      user-select: none;
      @include flex(column, end, center);
      text-align: center;
    }

    .deco-ball {
      margin-top: 40px;
      z-index: -1;
      width: 80px;
      height: 80px;
      background: $white;
      border-radius: 50%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      color: $white;

      &::before {
        z-index: -1;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $primary;
        transform: translate(-50%, -50%);
      }
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: 30px;
      left: 0;
      width: 200%;
      height: 20px;
      background: $white;
      border-radius: 10px;
      transform: translateX(-25%);
    }
  }

  #trusted-words {
    @include flex(column, space-between, center);
    padding: 80px 0;
    background: rgba($primary-light, 0.1);
    clip-path: polygon(0 100%, 100% calc(100% - 30px), 100% 0, 0 30px);
    backdrop-filter: blur(60px);
    
    h2 {
      width: $part-width;
      font-size: calc($text-huge * 1.2);
      padding: 0 30px;
      margin: 0;
    }
  
    #loading {
      padding: 100px 0;
    }
  }

  #calshare-desc {
    @include flex(row, space-between, center);
    gap: 80px;
    width: 80%;
    margin: 250px 0 50px 0;

    & > img {
      width: min(30%, 400px);
      mask-image: linear-gradient(to bottom, black, black 80%, transparent);
    }

    & > div {
      padding: 0 40px;
    }

    p {
      #first-paragraph {
        font-size: $text-huge;
        margin-bottom: 80px;
      }
    }

    #stores-badges {
      @include flex(row, start, center);
      gap: 30px;
      margin-top: 80px;

      img {
        width: 200px;
      }

      a {
        transform: translateY(0);
        transition-duration: .3s;
        
        &:hover{
          transform: translateY(-10px);
        }
      }
    }
  }

  #wiki-lore {
    font-size: $text-huge;
    margin-top: 200px;
    margin-bottom: 40px;
    text-align: center;
  }

  @media screen and (max-width: 1000px) {
    padding: 0;

    #main-title {
      padding: 0 30px;
      align-items: start;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      img {
        margin-right: 25px;
      }

      .paragraph {
        @include flex(column, start, start);
        padding: 0;
        gap: 10px;
        width: 100%;
        text-align: center;

        span {
          width: 100%;
          border-bottom: none !important;
          text-align: center;
        }
      }
    }

    #goals-illu {
      margin: 50px 0;
      width: 100%;
      display: flex;
      overflow-x: auto;
      align-self: flex-start;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        display: none;
      }
      
      & > div {
        flex-shrink: 0;
        width: 100%;
        padding: 0 30px;
        scroll-snap-align: center;

        img {
          width: 80%;
        }
      }

      .deco-ball {
        width: 40px;
        height: 40px;

        &::before {
          width: 20px;
          height: 20px;
        }
      }
      
      &::before {
        height: 10px;
        width: calc(400% - 120px);
        bottom: 15px;
        transform: translateX(60px);
      }
    }

    #calshare-desc {
      flex-direction: column;
      margin-top: 50px;
      gap: 10px;

      img {
        width: 80%;
      }

      & > div {
        width: 100%;
        padding: 0;
      }

      p {
        #first-paragraph {
          font-size: calc($text-huge * 1.4);
          margin-bottom: 80px;
        }
      }

      #stores-badges {
        justify-content: space-around;

        img {
          width: 150px;
        }
      }
    }

    #wiki-lore {
      padding: 0 30px;
      font-size: calc($text-huge * 1.4);
    }
  }
}

@keyframes breathe {
  from {
    transform: rotate(-4deg) scale(1);
  }
  to {
    transform: rotate(-4deg) scale(1.05);
  }
}