@use '../../../vars.scss' as *;

// #pc-title {
//     margin-top: 180px;
//     width: 80%;
// }

// #pc-title .big-title {
//     margin-bottom: 0;
// }

// #pc-title .post-title {
//     margin-top: 10px;
// }

// #subtitle {
//     text-align: center;
//     font-size: 14px;
//     font-weight: 500;
//     margin: 50px 0 20px 0;
// }

// h1.big-title {
//     font-size: 35px;
//     line-height: 40px;
// }

// h2.post-title {
//     margin-top: 0px;
// }

.article {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.article h4 {
    text-align: left;
    width: 100%;
    font-size: x-large;
}
.article h5 {
    text-align: left;
    width: 90%;
    font-size: large;
}
.article p {
    width: 100%;
    text-align: justify;
    font-size: small;
    line-height: 20px;
}
.article a {
    color: $primary;
}

table {
    border: 2px solid $white;
}
td, th {
    padding: 10px;
    border-bottom: 1px solid $white;
}
tr:nth-last-child(1) td {
    border-bottom: none;
}
th:nth-last-child(1), td:nth-last-child(1) {
    border-left: 1px solid $white;
}

@media screen and (min-width: 800px) {
    #pc-title {
        margin-top: 200px;
        width: min(1500px, 70%);
    }

    h1.big-title {
        font-size: 60px;
    }

    #subtitle {
        margin: 100px 0 40px 0;
    }

    .article {
        width: min(1500px, 70%);
    }
}