@use '../../vars.scss' as *;

.project-section {
  position: relative;
  background: rgba($white, 0.05);
  width: min(100%, 1400px) !important;
  padding: 60px;
  margin: 200px 0;
  border-radius: calc($base-radius * 2);
  border: solid 2px rgba($gray, 0.8);
  backdrop-filter: blur(50px);
}

.section-content {
  width: 50%;
  
  h2 {
    font-size: calc($text-xxl * 1.2);
    width: min-content;
    margin: 0 0 -0.15em 0;
  }

  .subtitle {
    color: $primary;
    position: relative;
    width: fit-content;
    font-weight: bold;
    margin: 0;
  }

  p {
    margin: 100px 0 50px 0;
  }
}

.decorated-section {
  @include flex(row, space-between, end);

  
  .section-decoration {
    width: 35%;
    filter: $black-shadow;
  }
}

#calshare-section-wrapper {
  @include flex(column, space-between, center);
}

#calshare-section {
  overflow: visible;

  svg#Calshare_ios {
    width: min(30%, 150px);
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(0, 80%) rotate(-3deg);
    
    .filled {
      fill: $white;
    }

    .stroked {
      fill: none;
      stroke: $white;
      stroke-miterlimit: 10;
      stroke-width: 20px;
    }

    .stroked-2 {
      stroke-width: 5px;
    }

    #bg {
      fill: $primary;
    }

    #start {
      transform-origin: 356px 121px;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      transform: scale(2);
      opacity: 0;
    }
    
    #dest {
      transform-origin: 134px 371px;
      animation-duration: 0.5s;
      animation-delay: 0.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      transform: scale(2);
      opacity: 0;
    }
    
    #S_line_path {
      stroke-dasharray: 822;
      stroke-dashoffset: 822;
      animation-duration: 1.7s;
      animation-delay: 1s;
      animation-fill-mode: forwards;
    }
  }

  #screen {
    width: min(100%, 400px);
    mask-image: linear-gradient(to bottom, black, black 80%, transparent);
  }

  #app-store {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60%;
    transition-duration: .3s;
    transform: translate(0, 110%);

    &:hover {
      transform: translate(0, 80%);
    }
  }
  
  #google-play {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60%;
    transition-duration: .3s;
    transform: translate(-120%, 100%);

    &:hover {
      transform: translate(-120%, 70%);
    }
  }
}

@media screen and (max-width: 1000px) {
  .project-section {
    padding: 30px;
  }

  .section-content {
    width: 100%;

    p {
      margin: 40px 0 30px 0;
    }
  }

  #calshare-section {
    svg#Calshare_ios {
      transform: translate(20%, -60%) rotate(3deg);
    }

  }
}

@media screen and (max-width: 800px) {
  .decorated-section {
    flex-direction: column;

    .section-decoration {
      margin-top: 50px;
      width: 100%;
    }
  }

  #calshare-section {
    svg#Calshare_ios {
      #start, #dest {
      opacity: 1;
        transform: scale(1.1);
      }
    }

    #app-store {
      transform: translate(-50%, 60%);
      left: 50%;
  
      &:hover {
        transform: translate(-50%, 60%);
      }
    }
  
    #google-play {
      transform: translate(-50%, 160%);
      left: 50%;
  
      &:hover {
        transform: translate(-50%, 160%);
      }
    }
  }

}

.drawPath {
  animation-name: drawPath;
}

@keyframes drawPath {
  from {
    stroke-dashoffset: 822;
    animation-timing-function: cubic-bezier(0.165, 0.4, 0.44, 1);
  }
  to {
    stroke-dashoffset: 0;
  }
}

.enterGeneric {
  animation-name: enterGeneric;
}

@keyframes enterGeneric {
  from {
    transform: scale(2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}