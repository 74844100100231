@use './vars.scss' as *;
@use 'sass:math' as math;

* {
  font-family: "Poppins", -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: $white;
  box-sizing: border-box;
  // outline: 1px red solid !important;

  &::selection {
    background: rgba($primary, 0.6);
    color: $white;
  }
}

body {
  padding: 0;
  margin: 0;
  background: $black;
  overflow-x: hidden;
  scrollbar-color: $primary;
}

.page {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  @include flex(column, space-between, center);
}

#top-logo {
  width: 100%;
  padding: 20px 0;
  mask-image: $sides-mask;
  height: fit-content;
  overflow: hidden;

  svg {
    position: relative;
    left: 50%;
    width: max(100%, 800px);
    transform: translate(-50%, 0);
  }

  path {
    fill: $primary;
  }

  text {
    fill: $primary;
    font-weight: 600;
    font-size: $text-medium;
  }
}

.footer {
  margin-top: 200px;
  width: 100%;
  @include flex(column, space-between, center);
}

#links {
  width: 100%;
  @include flex(row, space-around, center);
  flex-wrap: wrap;
  padding: 20px;
  background: $white;
  
  a {
    margin: 10px 10px;
    color: $black;
  }
}

section {
  overflow-x: hidden;
  margin: 50px 0;
  padding: 0 30px;

  &.part-page {
    width: $part-width;
  }

  &.full-page {
    width: 100%;
  }
}

p {
  font-size: $text-medium;
}

em {
  font-weight: bold;
  font-style: normal;
  filter: $black-shadow;
}

mark {
  background: rgba($white, 0.8);
  color: $black;
  padding: 2px;
}

#full-page-loading {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: min(50%, 150px);
  }

  h2 {
    color: $white;
    text-align: center;
  }
}

#main-word {
  font-weight: bold;
  margin-bottom: 100px;
  
  h2 {
    font-size: calc($text-huge * 1.6);
    margin: min(60px, 0.6em) 0;
  }
}

.highlighted {
  color: transparent;
  position: relative;
  background: linear-gradient(to right, #FB0C22, $primary);
  background-clip: text;
  background-position: 50% 50%;
  border-bottom: solid 6px $white;
  overflow: hidden;
}

.fancy-link {
  position: relative;
  font-size: $text-medium;
  font-weight: 600;
  text-decoration: none;
  transition-duration: .3s;
  font-variant-caps: all-small-caps;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    height: 2px;
    width: 100%;
    background: $white;
    transition-duration: 0.5s;
    transform-origin: left;
    transform: scaleX(0);
  }
  
  &:hover {
    color: $primary;
    font-size: $text-big;
    
    &::after {
      height: 6px;
      background: $primary;
      transform: scaleX(1);
    }
  }
}

.link {
  font-size: $text-small;
  color: $primary;
  cursor: pointer;
  position: relative;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 3px;
    border-radius: 2px;
    width: 100%;
    background: $primary;
    transform-origin: left;
    transform: scaleX(0);
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
  }

  &:hover::after {
    transform: scaleX(1);
  }
}

input, textarea {
  position: relative;
  outline: none;
  border: none;
  background: rgba($white, 0.3);
  font-size: $text-medium;
  padding: 15px 25px;
  width: 100%;
  border-radius: $base-radius;
  color: $white;
  transition-duration: 0.3s;
  
  &:active {
    outline: none;
  }

  &::placeholder {
    color: rgba($white, 0.5);
    transition-duration: 0.3s;
  }
}

input[type="checkbox"], input[type="radio"] {
  appearance: none;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 38%;
  padding: 0;
  background-color: rgba($white, 0.3);
  cursor: pointer;
  margin: 20px;

  &::after {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
  }

  &:checked {
    background-color: $primary;

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
    }
  }
}

input[type="radio"] {
  border-radius: 50%;
  position: relative;

  &::after {
    width: calc(100% - 13px);
    height: calc(100% - 13px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:checked {
    background-color: rgba($white, 0.3);
  }

  &:checked::after {
    background-image: none;
    background-color: $primary;
  }
}

path, circle, line, polygon {
  fill: $white;
}

.first-word {
  font-size: $text-big;
  font-weight: bold;
}

.decoration {
  position: absolute;
  z-index: -1;
}

.decoration-color-primary {
  path, circle, rect, line, polygon {
    fill: $primary;
  }
}

.decoration-color-white {
  path, circle, rect, line, polygon {
    fill: $white;
  }
}

.decoration-color-lightPrimary {
  path, circle, rect, line, polygon {
    fill: $primary-light;
  }
}

.decoration-color-gray {
  path, circle, rect, line, polygon {
    fill: $gray;
  }
}

#auth-message {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: fit-content;
  padding: 10px 20px;
  margin-bottom: 20px;
  transform: translate(-50%, 0);
  font-size: $text-small;
  background: rgba($gray, 0.4);
  border-radius: $base-radius;
  z-index: 10;
}

.profile-pic {
  min-width: 200px;
  min-height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

#already-member {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 30px;
  z-index: 10;
  filter: $black-shadow;

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }
}

button {
  border: none;
  outline: none;
  padding: 0.9em 1.6em;
  border-radius: $base-radius;
  font-size: $text-medium;
  font-weight: 600;
  transition-duration: .3s;
  cursor: pointer;
  filter: drop-shadow(0 0 0 rgb(255, 174, 120));
  transform: translate3d(0, 0, 0);
  color: $white;

  &:not(:disabled):hover {
    filter: drop-shadow(0 0 20px rgba($primary-light, 0.4));
  }

  &:disabled {
    cursor: not-allowed;
  }
}

button.primary {
  position: relative;
  overflow: hidden;
  background: $primary;

  &:disabled {
    background: $gray;
    color: $black;
  }

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: $primary-light;
    transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 10deg);
  }

  &::after {
    content: attr(data-icon);
    position: absolute;
    width: 100%;
    padding: 1em 0;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translate3d(0, 100%, 0) scale(0.5);
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  }

  &:not(:disabled):hover {
    &::before {
      animation: animateButtonHover .3s forwards ease-in;
    }

    &::after {
      transform: translate3d(0, 0, 0) scale(1.4);
      opacity: 1;
    }
  }
}

button.secondary {
  border: solid 4px $primary;
  background: none;

  &:disabled {
    border: solid 4px $gray;
    color: $gray;
  }
  
  &:not(:disabled):hover {
    color: $primary;
  }
}

@media screen and (max-width: 1000px) {
  .highlighted {
    border-bottom: solid 4px $white;
  }

  .decoration:not(#already-member) {
    display: none;
  }
}

@keyframes animateButtonHover {
  50% {
    transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes animateHighlight {
  80% {
    transform: translate3d(-50%, -200%, 0) rotate(-20deg);
  }
  100% {
    transform: translate3d(50%, 200%, 0) rotate(-20deg);
  }
}