@use '../../../vars.scss' as *;

#registered-organisations {
  @include flex(column, space-between, center);
  padding: 80px 0 0 0;
  background: $white;
  clip-path: polygon(0 100%, 100% calc(100% - 30px), 100% 0, 0 30px);
  backdrop-filter: blur(60px);
    
  h3 {
    width: $part-width;
    color: $black;
    font-size: $text-big;
    margin-bottom: 10px;
    margin: 0;
    padding: 0 20px;
  }
  
  #loading {
    padding: 100px 0;
  }

  #organisations-list-container {
    display: inline-flex;
    width: 100%;
    overflow-x: hidden;
    mask-image: $sides-mask;
  }

  ul {
    display: inline-flex;
    list-style: none;
    animation-timing-function: linear;
    animation-name: scrollWords;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    transform: translate3d(0, 0, 0);
    
    &::-webkit-scrollbar {
      display: none;
    }

    li {
      width: min(150px, 35vw);
      margin: max(5vw, 50px);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:nth-child(1) {
        margin-left: 0;
      }

      img {
        width: 80%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 50%;
      }

      p {
        text-align: center;
        color: $black;
      }
    }
  }
}

.organisations-step {
  h3 {
    font-size: $text-huge;
  }

  #images {
    margin: 100px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 60px;
    flex-wrap: wrap;
  }

  img, video {
    width: min(90%, 350px);
    border-radius: $base-radius;
  }
  
  video {
    border-radius: calc($base-radius * 2);
  }
}

@keyframes scrollWords {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-100%, 0);
  }
}