@use '../../vars.scss' as *;

.main-page-words h2 {
  font-size: $text-xl !important;
}

#goals {
  margin-top: 40px;

  .paragraph {
    width: $part-width;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

#swun-goals {
  @include flex(row, center, center);
  width: 100%;
  overflow: hidden;
  
  #sentence-start {
    margin: 0 10px 0 0;
    padding: 0;
    text-align: right;
    font-weight: 400;
    min-width: 115px;
    filter: drop-shadow(0 0 20px rgba($primary-light, 0.4));
  }

  .swun-goal {
    color: $gray;
    font-weight: 300;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.77, 0, 0, 0.77);
    opacity: 0;
    padding: 0;
    margin: 0;
  }
  
  div {
    position: relative;
    
    @for $i from 0 through 2 {
      p:nth-child(#{$i + 1}) {
        animation-delay: 4s * $i;
        animation-name: animateGoal#{$i};
      }

      @keyframes animateGoal#{$i} {
        0% {
          transform: translate(0, -100% - calc(100% * $i));
          opacity: 0;
          animation-timing-function: cubic-bezier(0.5, 0, 0, 1);
        }
        35% {
          transform: translate(0, 100% - calc(100% * $i));
          opacity: 1;
          animation-timing-function: cubic-bezier(0.5, 0, 0, 1);
        }
        65% {
          transform: translate(0, 300% - calc(100% * $i));
          opacity: 0;
        }
      }
    }
  }
}

#founder-words {
  margin-top: 200px;
  padding: 80px 30px 60px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: visible;

  div {
    display: flex;
    flex-direction: column;
    align-items: start;
    position: relative;

    width: min(100%, 1000px);

    h3 {
      color: $black;
      font-size: calc($text-medium * 1.2);
      position: relative;

      &::before, &::after {
        content: '"';
        position: absolute;
        top: 0;
        left: 0;
        font-size: 80px;
        transform: translate(-50%, -50%) rotate(-20deg);
      }

      &::after {
        left: 100%;
        transform: translate(-50%, -50%) rotate(20deg);
      }
    }

    p {
      color: $black;
      align-self: flex-end;
    }

    #images {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(80%);
      
      div {
        width: 120px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;

        &:nth-child(2) {
          transform: translateY(5%);
        }

        // Using a pseudo-element as a background prevents white small outline on the edge of the image
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 99.5%;
          height: 99.5%;
          background: $white;
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }

        img {
          width: 100%;
          transform: scale(1.05);
        }
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(100% 30px, 100% 100%, 0 calc(100% - 30px), 0 0);
    background: $white;
  }
}

#projects-title {
  font-size: calc($text-huge * 1.6);
  margin: 200px 0 200px 0;

  #highlighted {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 112%;
      height: 100%;
      background: $primary;
      z-index: -1;
      opacity: 0.3;
      transform-origin: left;
      transform: translateX(-10px) scaleX(0);
    }
  }
}

@media screen and (max-width: 800px) {
  #highlighted::after {
    transform: translateX(-10px) scaleX(1);
  }
}

.animateDiscovery {
  &::after {
    animation: animateDiscovery 0.5s forwards;
  }
}

@keyframes animateDiscovery {
  from {
    transform: translate(-10px, 5%) scaleX(0);
  } to {
    transform: translate(-10px, 5%) scaleX(1);
  }
}

#calshare-section {
  margin-top: 0;
}

@media screen and (max-width: 1000px) {
  #projects-title {
    margin: 140px 0 120px 0;
  }

  .main-page-words #main-word {
    margin-bottom: 0;
  }

  #founder-words #images {
    transform: translateY(100%) !important;

    div {
      width: 80px !important;

      &:nth-child(2) {
        transform: translateY(10%) !important;
      }
    }
  }
}