@use '../../vars.scss' as *;

#visuals {
  margin-top: 50px;
  padding-bottom: 20px;
  width: min(100%, 1400px);
  @include flex(row, space-between, start);

  .visual-wrapper {
    width: 42%;
    @include flex(column, space-between, center);

    h3 {
      font-size: $text-big;
      margin-bottom: -0.6em;
    }
    
    .format-indicator {
      color: $gray;
      font-weight: 500;
    }

    img {
      width: 100%;
    }

    .dl-button {
      padding: 0.9em 1.6em;
      background: $primary;
      text-decoration: none;
      border-radius: $base-radius;
      font-size: $text-medium;
      font-weight: 600;
      position: relative;
      overflow: hidden;
      transition-duration: .3s;
      cursor: pointer;
      filter: drop-shadow(0 0 0 rgb(255, 174, 120));
      transform: translate3d(0, 0, 0);
      color: $white;

      &:not(:disabled):hover {
        filter: drop-shadow(0 0 20px rgba($primary-light, 0.4));
      }

      &::before {
        content: '';
        position: absolute;
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
        background: $primary-light;
        transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 10deg);
      }

      &::after {
        content: attr(data-icon);
        position: absolute;
        text-align: center;
        width: 100%;
        padding: 1em 0;
        top: 0;
        left: 0;
        opacity: 0;
        transform: translate(0, 100%) scale(0.5);
        transition-duration: .3s;
        transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
      }

      &:hover {
        &::before {
          animation: animateButtonHover .3s forwards ease-in;
        }

        &::after {
          transform: translate(0, 0) scale(1.4);
          opacity: 1;
        }
      }
    }
  }

  svg {
    width: 2em;

    path, polygon {
      fill: none;
      stroke: $black;
      stroke-width: 30px;
      stroke-linejoin: round;
    }
  }

  #post, #story {
    margin: 3em 0;
  }

  #post {
    #header, #footer {
      width: 100%;
      height: fit-content;
      padding: 15px;
      background: $white;
      @include flex(row, flex-start, center);
    }

    #header {
      border-radius: $base-radius $base-radius 0 0;

      #profile-pic {
        height: clamp(40px, 3.1vw, 60px);
        width: clamp(40px, 3.1vw, 60px);
        border-radius: 50%;
        background: $black;
      }

      #username {
        height: clamp(8px, 0.78vw, 15px);
        margin-left: 20px;
        width: clamp(100px, 30%, 400px);
        border-radius: 10px;
        background: $black;
      }
    }

    #footer {
      border-radius: 0 0 $base-radius $base-radius;
      // Bug très bizarre ici
      margin-top: -7px;

      svg {
        margin-right: 10px;
      }
    }
  }

  #story {
    position: relative;
    border-radius: $base-radius;
    overflow: hidden;

    path, polygon {
      stroke: $white;
    }

    // Bug très bizarre ici
    img {
      margin-bottom: -7px;
    }

    #header, #footer {
      position: absolute;
      width: 100%;
      height: fit-content;
      padding: 15px;
      filter: drop-shadow(0 0 20px rgba($black, 0.5));
    }

    #header {
      top: 0;
      left: 0;
      @include flex(column, flex-start, start);

      #progress-bar {
        position: relative;
        width: 100%;
        height: 4px;
        background: rgba($white, 0.4);
        border-radius: 2px;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 42%;
          height: 100%;
          background: $white;
          border-radius: 2px;
        }
      }

      #profile {
        width: 100%;
        margin-top: 10px;
        @include flex(row, flex-start, center);

        #profile-pic {
          height: clamp(40px, 3.1vw, 60px);
          width: clamp(40px, 3.1vw, 60px);
          border-radius: 50%;
          background: $white;
        }
  
        #username {
          height: clamp(4px, 0.78vw, 8px);
          width: clamp(100px, 30%, 400px);
          margin-left: 20px;
          border-radius: 4px;
          background: $white;
        }
      }
    }

    #footer {
      bottom: 0;
      right: 0;
      width: fit-content;
    }
  }
}

@media screen and (max-width: 1000px) {
  #visuals {
    flex-direction: column;
    margin-top: 0;

    .visual-wrapper {
      width: 100%;
      margin-bottom: 100px;
    }
  }
}

@keyframes animateButtonHover {
  50% {
    transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 1, 0deg);
  }
}