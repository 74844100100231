@use '../../vars.scss' as *;

#account-section {
  #account {
    @include flex(row, space-around, start);

    .profile-pic {
      margin: 40px;
      min-width: min(250px, 50vw);
      min-height: min(250px, 50vw);
    }

    #infos {
      @include flex(column, flex-start, start);
      padding: 40px;
      width: 80%;

      #name {
        position: relative;
        font-size: $text-xxl;
        margin: 0;
        margin-bottom: -0.3em;
      }
      
      #username {
        font-size: $text-huge;
        font-weight: 400;
        margin: 0;
        color: $primary;
        filter: drop-shadow(0 0 20px rgba($primary, 0.5));
      }

      #bio {
        font-size: $text-medium;
        margin-top: 50px;
      }
    }
  }

  #actions {
    margin-top: 150px;
    padding-bottom: 50px;

    h2 {
      font-size: calc($text-huge * 1.25);
    }

    h2:nth-child(1) {
      margin-bottom: -0.5em;
    }

    #buttons {
      @include flex(row, space-around, center);
      margin-top: 100px;
    }

    #disconnect {
      border: none;
      color: $red;

      &:hover {
        color: $red;
        filter: drop-shadow(0 0 20px rgba($red, 0.5));
      }
    }
  }
}

#modify-profile {
  width: 300px;
  right: 25%;
  transform: rotate(-2deg);

  p {
    margin-top: -0.2em;
    font-size: $text-small;
  }
}

.arrow {
  width: 150px;
  transform: rotate(76deg);

  path, polyline {
    fill: none;
    stroke: $white;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 15px;
  }
}

@media screen and (max-width: 1100px) {
  #main-word::after {
      bottom: -80%;
      right: 5%;
      font-size: $text-xxl;
  }

  #account-section {
    width: 100%;

    #account {
      flex-direction: column;
      align-items: center;

      #infos {
        padding: 0;
      }
    }

    #actions {
      margin-top: 100px;
      padding: 0;

      #buttons {
        margin-top: 50px;
        flex-direction: column;

        button {
          margin: 20px 0;
        }
      }
    }
  }
}