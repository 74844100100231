@use '../../../vars.scss' as *;

.wiki-page {
  position: relative;
  width: 100%;
  @include flex(row, space-between, stretch);

  #menu-bar-container {
    position: sticky;
    top: 0;
  }
  
  #menu-bar {
    position: relative;
    top: 0;
    right: 0;
    $margin: 20px;
    width: min(500px, calc(100% - $margin * 2));
    max-height: calc(100vh - $margin * 2);
    padding: 50px;
    margin: 20px;
    background: $white;
    border-radius: $base-radius * 2;

    a, h2 {
      color: $black;
      text-decoration: none;
    }

    h2 {
      font-size: $text-big;
      margin: 0;
    }

    #links {
      padding: 0;
      margin-top: 50px;
      @include flex(column, start, start);
      gap: 10px;
      
      a {
        position: relative;
        margin: 0;
        width: 100%;
        text-align: left;
        padding: 10px 20px;
        font-size: $text-small;
        color: rgba($black, 0.8);
        transform: translate(0);
        transition-duration: .3s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
        border-radius: $base-radius;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($black, 0.08);
          border-radius: $base-radius;
          transition-duration: .3s;
          transform: translate(0, -50%);
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $black;
          border-radius: $base-radius;
          transition-duration: .3s;
          transition-timing-function: ease-out;
          transform: translateX(-101%);
          z-index: -1;
        }

        &:hover {
          transform: translateX(15px);
          color: $white;

          &::after {
            transform: translateX(0);
          }
        }

        &.active {
          transform: translateX(15px);
          color: $white;
          overflow: visible;

          &::before {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            top: 50%;
            transform: translate(-200%, -50%);
            background: $black;
          }

          &::after {
            transform: translateX(0);
          }
        }
      }
    }
  }

  #wiki-content {
    width: 100%;
    padding: 80px;
    text-align: left;
    @include flex(column, start, start);
  }

  .title {
    margin-bottom: 100px;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;

    #menu-bar {
      #links {
        margin-top: 30px;
      }
    }

    #wiki-content {
      padding: 30px;
    }

    .title {
      margin-bottom: 40px;
    }
  }
}