@use '../vars.scss' as *;

.input {
  width: min(100%, 400px);

  #input-label {
    font-size: $text-medium;
  }

  .input-container {
    @include flex(row, flex-start, start);
  }

  #input-wrapper {
    @include flex(row, space-between, center);
    width: 100%;

    input, textarea {
      width: 100%;
    }
  }

  div.helper {
    padding-left: 30px;
    width: 50%;
    transform: translate(-5%, 0);
    opacity: 0;
    transition-duration: 0.5s;

    p {
      font-size: $text-medium;
    }

    &.shown {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  &:has(.helper) {
    width: min(100%, 800px);

    #input-wrapper {
      width: 50%;
    }
  }

  position: relative;
  transition-duration: 0.3s;
  
  p {
    color: rgba($white, 0.3);
    margin: 0 0 5px calc($base-radius - 5px);
    font-size: 24px;
    transition-duration: 0.3s;
    position: relative;
    width: fit-content;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 110%;
      height: 4px;
      background: rgba($white, 0.4);
      transform-origin: left;
      transform: scaleX(0) translate(-4%, 0);
      transition-duration: 0.3s;
      border-radius: 2px;
      z-index: -1;
    }
  }

  &:has(input:focus), &:has(textarea:focus) {
    > p {
      font-size: 28px;
      color: rgba($white, 0.4);
      margin-bottom: 15px;

      &::after {
        transform: scaleX(1) translate(-4%, 0);
      }
    }

    input, textarea {
      background: rgba($white, 0.4);

      &::placeholder {
        color: rgba($white, 0.6);
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .input-container {
      flex-direction: column;
    }

    &:has(.helper) {
      #input-wrapper {
        width: 100%;
      }
    }

    div.helper {
      width: 100%;
      margin-top: 10px;
      padding: 0;
    }
  }
}

.pwd-eye {
  width: 60px;
  padding: 10px;
  cursor: pointer;

  path {
    fill: none;
    stroke-width: 1.5;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke: $gray;
  }
}

.input-valid {
  > p {
    color: $green !important;

    &::after {
      background: $green !important;;
    }
  }
}

.input-invalid {
  > p {
    color: $red !important;

    &::after {
      background: $red !important;;
    }
  }
}